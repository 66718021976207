<template>
<div id="main">
<div class="chart"> <div id="chart2" style="width: 6000px;height:400px;"></div>
</div>
</div>

</template>

<script>
import * as echarts from 'echarts';
export default {
  name: 'AccueilA',
  mounted () {

var chartDom2 = document.getElementById('chart2');
var myChart2 = echarts.init(chartDom2);
var option2;

option2 = {
  graphic: {
    elements: [
      {
        type: 'text',
        left: 'center',
        top: 'center',
        style: {
          text: 'germaindeffontaines.fr',
          fontSize: 80,
          fontWeight: 'bold',
          lineDash: [0, 200],
          lineDashOffset: 0,
          fill: 'transparent',
          stroke: '#000',
          lineWidth: 1
        },
        keyframeAnimation: {
          duration: 3000,
          loop: true,
          keyframes: [
            {
              percent: 0.7,
              style: {
                fill: 'transparent',
                lineDashOffset: 200,
                lineDash: [200, 0]
              }
            },
            {
              // Stop for a while.
              percent: 0.8,
              style: {
                fill: 'transparent'
              }
            },
            {
              percent: 1,
              style: {
                fill: 'black'
              }
            }
          ]
        }
      }
    ]
  }
};

option2 && myChart2.setOption(option2);













  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;

}


</style>